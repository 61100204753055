<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
				<v-card class="shadowCard">
					<v-card-title class="text-subtitle-1">
					  Faltantes de encuesta de satisfacción
					  <v-spacer></v-spacer>
	      		<v-btn
							color="primary"
							dark
							@click="initialize()"
							small
							tile
						>
							Actualizar
						</v-btn>
					</v-card-title>
					<v-card-text >

		      	<v-row>
		      		<v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  item-text="ciclo"
                  item-value="id_ciclo"
                  return-object
                >
                </v-autocomplete>
              </v-col>
              <v-col>
              	<v-radio-group
						      v-model="opEscuela"
						      row
						    >
						      <v-radio
						        label="Inbi"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="Fast"
						        :value="2"
						      ></v-radio>
						    </v-radio-group>
              </v-col>
		      		<v-spacer></v-spacer>
			      	<v-col cols="12" sm="6" md="6" lg="4" xl="4" class="pb-0">
			          <v-text-field
			            v-model="search"
			            dense
			            filled
			            label="Buscar"
			            append-icon="mdi-magnify"
			          ></v-text-field>  
			        </v-col>
		      	</v-row>

          	<v-row>
          		<v-col cols="12">
          			<div>
          				<b>Total: {{ alumnos }}</b><br/>
          				<b>Faltan: {{ faltantes.length }}</b><br/>
          				<b>Avance: {{ ((( alumnos - faltantes.length ) / faltantes.length ) * 100 ).toFixed(2 ) }} % </b>
          			</div>
          		</v-col>
          	</v-row>
		        <v-data-table
		          :headers="headersFaltantes"
		          :items="faltantes"
		          class="elevation-0"
		          dense
		          :search="search"
		        >
		        </v-data-table>
          </v-card-text>
				</v-card> 
			</v-col>
		</v-row>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>

<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';

	import validarErrores       from '@/mixins/validarErrores'
	import XLSX                 from 'xlsx'
	import funcionesExcel       from '@/mixins/funcionesExcel'

	export default {
		components:{
			Alerta,
			carga,
		},

		mixins: [ validarErrores, funcionesExcel ],

		data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headersFaltantes:[
      	{ text: 'Nombre'         , value: 'alumno'                },  
      	{ text: 'Plantel'        , value: 'plantel'               },  
      ],

			search:'',
      data:[],

			ciclos:[],
      ciclo:null,
      faltantes:[],
			opEscuela: 1,
			alumnos:0,
		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario', 'getEscuela']),

		},

		watch: {
			ciclo( value ){

				if( value && this.opEscuela ){ this.initialize() }

			},


			opEscuela( value ){

				if( value && this.ciclo ){ this.initialize() }

			}
		},

		async created () {
    	await this.getCiclos()
		},

		methods:{

   		getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data.filter( el => !el.ciclo.match('FE') && !el.ciclo.match('INVER') && !el.ciclo.match('CAMBIOS') && !el.ciclo.match('EXCI') )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

			exportar( ){
        this.exportExcel( this.data  , 'teachers')
			},

			initialize ( ) {
				this.cargar = true
				const payload = {
					id_ciclo   : this.getEscuela == 1 ? this.ciclo.id_ciclo : this.ciclo.id_ciclo_relacionado
				}

				const planteles = this.getdatosUsuario.planteles.map((registro)=>{ return registro.idplantel })

				this.$http.post('encuestas.satisfaccion.resultados', payload).then(response=>{
					this.faltantes     = response.data.faltantes.filter( el => planteles.includes( el.id_plantel ) )
					this.alumnos       = response.data.alumnos.filter( el => planteles.includes( el.id_plantel ) ).length
					this.cargar       = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},
    }
	}
</script>



